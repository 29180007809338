import React from "react";
import { css } from "linaria";
import PageContent from "components/page/PageContent";
import SlideInContainer from "components/common/SlideInContainer";
import { StaticImage } from "gatsby-plugin-image";

const sectionContainer = css`
  background: var(--nuetral-100);
`;

const contentContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--max-width);
  width: 100%;
  padding: 70px 0px 122px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 50px 0px 50px;
  }

  div {
    width: 100%;
  }
`;

const titleContainer = css`
  width: 100%;

  > div {
    width: 100%;

    div {
      display: flex;
      justify-content: center;

      @media (max-width: 800px) {
        justify-content: flex-start;
      }
    }
  }
`;

const titleClass = css`
  display: inline-block;
  background-image: linear-gradient(to left, var(--teal-500), var(--blue-500));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @media (max-width: 800px) {
    text-align: left;
  }
`;

const imageContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  column-gap: 25px;
  margin-top: 72px;

  > div {
    width: unset;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 54px;
    margin-top: 50px;

    > div {
      width: 30%;
    }
  }

  @media (max-width: 550px) {
    padding: 0px;

    > div {
      width: 45%;
    }
  }
`;

const textContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 35px;

  @media (max-width: 900px) {
    width: 35%;
  }
  @media (max-width: 800px) {
    align-items: flex-start;
    text-align: left;
  }
  @media (max-width: 600px) {
    width: 45%;
  }
  @media (max-width: 365px) {
    width: 45%;
  }
`;

const iconClass = css`
  height: 50px;
  pointer-events: none;

  @media (max-width: 445px) {
    height: 40px;
  }
`;

export default function DoorReaderFeatures() {
  return (
    <section className={sectionContainer}>
      <div className={contentContainer}>
        <PageContent>
          <div className={titleContainer}>
            <SlideInContainer>
              <h1 className={titleClass}>Door Reader Features</h1>
            </SlideInContainer>
          </div>
          <SlideInContainer>
            <div className={imageContainer}>
              <div className={textContainer}>
                <div className={iconClass}>
                  <StaticImage
                    src="./img/tools.png"
                    placeholder="blurred"
                    alt="Location pin"
                    style={{ width: "56.66px" }}
                  />
                </div>
                <h2>
                  Easy and <br /> Simple to Install
                </h2>
              </div>
              <div className={textContainer}>
                <div className={iconClass}>
                  <StaticImage
                    src="./img/secure.png"
                    placeholder="blurred"
                    alt="Camera"
                    style={{ width: "54.94px" }}
                  />
                </div>
                <h2>
                  Enterprise-Grade <br /> Encryption
                </h2>
              </div>
              <div className={textContainer}>
                <div className={iconClass}>
                  <StaticImage
                    src="./img/cloud.png"
                    placeholder="blurred"
                    alt="Warning"
                    style={{ width: "57.22px" }}
                  />
                </div>
                <h2>
                  Reliable and Intuitive <br /> Cloud Management
                </h2>
              </div>
              <div className={textContainer}>
                <div className={iconClass}>
                  <StaticImage
                    src="./img/wave.png"
                    placeholder="blurred"
                    alt="Warning"
                    style={{ width: "51.52px" }}
                  />
                </div>
                <h2>
                  Access via <br /> Touchless Entry
                </h2>
              </div>
            </div>
          </SlideInContainer>
        </PageContent>
      </div>
    </section>
  );
}
