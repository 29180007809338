import { css } from "linaria";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import PageContent from "components/page/PageContent";
import { MainParagraph, Title } from "components/rhombus-UI/theme/typography";

const sectionClass = css`
  width: 100%;
  min-height: 570px;
  max-height: calc(100vh - 90px);
  display: grid;
`;

const contentClass = css`
  display: grid;
  grid-area: 1/1;
  place-items: center;
  position: relative;
`;

const textClass = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  min-height: 570px;
  max-height: calc(100vh - 90px);

  @media (max-width: 700px) {
    align-items: center;
  }

  animation: fadein 2.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  p {
    color: white;
    margin-bottom: 30px;
    max-width: 495px;

    @media (max-width: 400px) {
      margin-bottom: unset;
    }
  }
`;

const textWrapper = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 25px;
  max-width: 495px;
  color: var(--white);
`;

const pageContentClass = css`
  height: 100%;
`;

export default function DoorReadersOverviewHero() {
  const renderContent = data => {
    const images = withArtDirection(getImage(data.web), [
      {
        media: "(max-width: 700px)",
        image: getImage(data.mobile),
      },
    ]);
    return (
      <section className={sectionClass}>
        <GatsbyImage
          image={images}
          alt=""
          style={{
            gridArea: "1/1",
            pointerEvents: "none",
          }}
          imgStyle={{ objectPosition: "left" }}
          loading="eager"
        />
        <div className={contentClass}>
          <PageContent contentClass={pageContentClass}>
            <div className={textClass}>
              <div className={textWrapper}>
                <Title>Seamlessly Manage Employees and Guests</Title>
                <MainParagraph>
                  Gain customizable control over how employees and visitors move
                  throughout the building, all from an easy-to-use dashboard.
                </MainParagraph>
              </div>
            </div>
          </PageContent>
        </div>
      </section>
    );
  };
  const GET_IMAGES = graphql`
    query {
      web: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/door-readers-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      mobile: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/door-readers-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
