import { css } from "linaria";
import React from "react";
import RoundLink from "components/common/RoundLink";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import PageContent from "components/page/PageContent";
import RhombusButton from "components/common/RhombusButton";
import {
  MainParagraph,
  PageTitle,
} from "components/rhombus-UI/theme/typography";
import { TextContainer } from "components/rhombus-UI/theme/containers";

const sectionClass = css`
  width: 100%;
  min-height: 90vh;
  margin-top: -80px;
  display: grid;
`;

const contentClass = css`
  display: grid;
  grid-area: 1/1;
  place-items: center;
  position: relative;
`;

const textClass = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  min-height: 570px;
  max-height: calc(100vh - 90px);
  color: var(--white);
  @media (max-width: 700px) {
    align-items: center;
  }
  animation: fadein 2.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  p {
    max-width: 445px;
  }
`;

const pageContentClass = css`
  height: 100%;
`;

export default function DoorReadersHeader({ formRef }) {
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({
        behavior: "smooth",
      });
      setTimeout(() => {
        const firstFormInput = document.querySelector(".hs-input");
        firstFormInput.focus();
      }, 900);
    }
  };

  const renderContent = data => {
    const images = withArtDirection(getImage(data.web), [
      {
        media: "(max-width: 700px)",
        image: getImage(data.mobile),
      },
    ]);
    return (
      <section className={sectionClass}>
        <GatsbyImage
          image={images}
          alt=""
          style={{
            gridArea: "1/1",
            pointerEvents: "none",
          }}
          imgStyle={{ objectPosition: "left", filter: "brightness(0.7)" }}
          loading="eager"
        />
        <div className={contentClass}>
          <PageContent contentClass={pageContentClass}>
            <div className={textClass}>
              <TextContainer width="600px">
                <PageTitle>Access Control Door Readers</PageTitle>
                <MainParagraph>
                  Quickly access your spaces by card, mobile app, or
                  wave-to-unlock gestures with Rhombus’ suite of modern,
                  touchless, smart readers.
                </MainParagraph>
                <RhombusButton
                  type="primary"
                  title="Request Demo"
                  path="/lp/access-control-a/"
                  style={{ marginTop: "0.5rem" }}
                />
                {/* TO DO: Add back link when pricing is released
                 <RoundLink
                  appearance="whiteBorder"
                  to="/pricing/"
                  className={linkClass}
                >
                  View Pricing
                </RoundLink> */}
              </TextContainer>
            </div>
          </PageContent>
        </div>
      </section>
    );
  };
  const GET_IMAGES = graphql`
    query {
      web: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/door-readers-header-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      mobile: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/door-readers-header-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
