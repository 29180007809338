import AccessControlBanner from "components/common/footer-banners/AccessControlBanner";
import React, { useRef } from "react";

import DoorReaderFeatures from "./DoorReaderFeatures";
import DoorReaderModels from "./DoorReaderModels";
import DoorReadersHero from "./DoorReadersHero";
import DoorReadersOverviewHeader from "./DoorReadersOverviewHeader";

export default function DoorReadersPage() {
  const formRef = useRef(null);
  return (
    <>
      <DoorReadersOverviewHeader formRef={formRef} />
      <DoorReaderModels />
      <DoorReadersHero />
      <DoorReaderFeatures />
      <AccessControlBanner />
    </>
  );
}
