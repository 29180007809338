import React from "react";

import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import DoorReadersPage from "components/access-control/door-readers/overview/DoorReadersPage";

export default function AccessControlLandingPage() {
  return (
    <GlobalLayout color="tranaprent" dark>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
      </Helmet>
      <DoorReadersPage />
    </GlobalLayout>
  );
}
