import useInView from "hooks/useInView";
import { css, cx } from "linaria";
import PropTypes from "prop-types";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";

const slideInContainer = css`
  position: relative;
`;

const animationSpeed = 0.5;
const slideInFromTop = css`
  @keyframes slide-from-top {
    0% {
      top: -100px;
      bottom: 100px;
    }
    100% {
      top: 0;
      bottom: 0;
    }
  }
  animation: slide-from-top ${animationSpeed}s ease-out;
`;

const slideInFromBottom = css`
  @keyframes slide-from-bottom {
    0% {
      bottom: -50px;
      top: 50px;
    }
    100% {
      bottom: 0;
      top: 0;
    }
  }

  animation: slide-from-bottom ${animationSpeed}s ease-out;
`;

const slideInFromLeft = css`
  @keyframes slide-from-left {
    0% {
      left: -100px;
      right: 100px;
    }
    100% {
      left: 0;
      right: 0;
    }
  }
  animation: slide-from-left ${animationSpeed}s ease-out;
`;

const slideInFromRight = css`
  @keyframes slide-from-right {
    0% {
      right: -100px;
      left: 100px;
    }
    100% {
      right: 0;
      left: 0;
    }
  }
  animation: slide-from-right ${animationSpeed}s ease-out;
`;

const slideInContent = css`
  position: absolute;
  opacity: 0;
  width: 100%;
`;

const contentVisible = css`
  transition-property: opacity;
  transition: ${animationSpeed}s;
  opacity: 1;
`;

const slideInFromClasses = {
  top: slideInFromTop,
  bottom: slideInFromBottom,
  left: slideInFromLeft,
  right: slideInFromRight,
};

/**
 * @deprecated
 */

export default function SlideInContainer({
  className,
  children,
  from,
  style,
  resetOnOutOfView,
}) {
  const [height, setHeight] = useState(0);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [firstSlideIn, setFirstSlideIn] = useState(true);
  const { lastInView, inView } = useInView(containerRef.current);

  const handleResize = useCallback(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.clientHeight);
    }
  }, []);

  // Add slide In class
  useLayoutEffect(() => {
    const slideInFromClass = slideInFromClasses[from];
    if (resetOnOutOfView || firstSlideIn) {
      if (contentRef.current) {
        if (inView) {
          setFirstSlideIn(false);
          contentRef.current.classList.add(contentVisible);
          if (lastInView === false) {
            contentRef.current.classList.add(slideInFromClass);
          }
        } else if (lastInView && !inView) {
          contentRef.current.classList.remove(slideInFromClass);
        }
      }
    }
  }, [from, lastInView, inView, firstSlideIn]);

  // create and add the resize observer
  useLayoutEffect(() => {
    handleResize(); // call it initially
    document.addEventListener("resize", handleResize);
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(contentRef.current);
    return () => {
      resizeObserver.disconnect();
      document.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <div
      className={slideInContainer}
      ref={containerRef}
      style={{ height: style?.height || height }}
    >
      <div
        className={cx(slideInContent, className)}
        ref={contentRef}
        style={style}
      >
        {children}
      </div>
    </div>
  );
}

SlideInContainer.defaultProps = {
  className: undefined,
  children: undefined,
  from: "bottom",
  style: undefined,
  resetOnOutOfView: false,
};

SlideInContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  from: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  style: PropTypes.object,
  resetOnOutOfView: PropTypes.bool,
};
