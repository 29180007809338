import { useLayoutEffect, useState } from "react";

import usePrevious from "./usePrevious";
import useScrollBounds from "./useScrollBounds";

export default function useInView(element) {
  const [inView, setInView] = useState(undefined);
  const lastInView = usePrevious(inView);
  const { scrollTop, scrollBottom } = useScrollBounds();

  useLayoutEffect(() => {
    if (!element) return;

    const elementScrollTop = element.getBoundingClientRect().top + scrollTop;
    const aboveBottomThreshold = scrollBottom - 50 >= elementScrollTop;
    const aboveBottom = scrollBottom >= elementScrollTop;
    if (!inView && aboveBottomThreshold) {
      setInView(true);
    } else if (inView && !aboveBottom) {
      setInView(false);
    } else if (inView === undefined && !aboveBottom) {
      setInView(false);
    }
  }, [element, inView, scrollTop, scrollBottom]);

  return {
    inView,
    lastInView,
  };
}
