import React from "react";
import { css } from "linaria";
import PageContent from "components/page/PageContent";
import SlideInContainer from "components/common/SlideInContainer";
import { graphql, Link, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RhombusButton from "components/common/RhombusButton";

const sectionClass = css`
  padding: 55px;

  @media (max-width: 750px) {
    padding: 55px 0px;
  }
`;

const titleContainer = css`
  margin-bottom: 73px;

  > div {
    width: 100%;

    div {
      display: flex;
      justify-content: center;
    }
  }
`;

const titleClass = css`
  display: inline-block;
  text-align: center;
  margin-bottom: 1.4rem;
  background-image: linear-gradient(to left, var(--teal-500), var(--blue-500));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const contentClass = css`
  display: flex;
  justify-content: center;
  column-gap: 200px;

  @media (max-width: 800px) {
    column-gap: 100px;
  }
  @media (max-width: 700px) {
    justify-content: space-around;
    column-gap: unset;
  }
`;

const columnClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 47px;
  width: calc(100% / 3);
  max-width: 261px;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    padding: 8px;
  }

  > div {
    width: 100%;
  }

  h2 {
    margin-bottom: 5px;
  }
  h1 {
    font-size: 24px;
    line-height: 33px;
  }
  h2,
  p {
  }
`;

const imageClass = css`
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 30px;
  transition: all 250ms;
  max-width: 221px;
  :hover {
    border-color: var(--blue-500);
  }

  @media (max-width: 1000px) {
    margin: 25px;
  }
`;

const linkClass = css`
  margin-top: 20px;
  display: inline-block;

  @media (max-width: 445px) {
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const imageContainer = css`
  display: flex;
  justify-content: center;

  div {
    width: fit-content;
  }
`;

const title = css`
  h3,
  a {
    text-decoration: none;
    color: #2b2e30;
    font-weight: 800;
    font-size: 24px;
    line-height: 1.5;
    transition: all 250ms;
    cursor: pointer;
    :hover {
      color: var(--blue-500);
    }
  }
`;

export default function DoorReaderModels() {
  const renderContent = data => {
    const sensorModels = [
      {
        image: data.dr20Image,
        name: "DR20",
        doorbell: false,
        videoIntercom: false,
        price: "249",
        link: "/access-control/door-readers/dr20/",
      },
      {
        image: data.dr40Image,
        name: "DR40",
        doorbell: true,
        videoIntercom: true,
        price: "899",
        link: "/access-control/door-readers/dr40/",
      },
    ];

    return (
      <section className={sectionClass}>
        <PageContent>
          <div className={titleContainer}>
            <SlideInContainer>
              <h1 className={titleClass}>Access Control Door Readers</h1>
            </SlideInContainer>
          </div>
          <div className={contentClass}>
            {sensorModels.map(model => {
              const imageRef = getImage(model.image);

              return (
                <div className={columnClass} key={model.name}>
                  <SlideInContainer>
                    <Link to={model.link} className={imageContainer}>
                      <GatsbyImage
                        image={imageRef}
                        alt={model.name}
                        className={imageClass}
                        imgStyle={{ width: "fit-content" }}
                        objectFit="contain"
                      />
                    </Link>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div className={title}>
                      <h3>
                        <Link to={model.link}>{model.name}</Link>
                      </h3>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div>
                      <h2>Wave to Unlock</h2>
                      <p>Included</p>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div>
                      <h2>NFC</h2>
                      <p>Credentials</p>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div>
                      <h2>BLE</h2>
                      <p>Credentials</p>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div>
                      <h2>Doorbell</h2>
                      <p>{model.doorbell ? "Included" : "-"}</p>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div>
                      <h2>Video Intercom</h2>
                      <p>{model.videoIntercom ? "Included" : "-"}</p>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div>
                      <h2>IP65</h2>
                      <p>Weatherproof</p>
                    </div>
                  </SlideInContainer>
                  {/* TO DO: Add back when pricing is released
                  <SlideInContainer>
                    <div>
                      <h2>${model.price}</h2>
                      <p>Starting Price</p>
                    </div>
                  </SlideInContainer> */}
                  <SlideInContainer>
                    <RhombusButton
                      type="gradient"
                      path={model.link}
                      title="Learn More"
                    />
                  </SlideInContainer>
                </div>
              );
            })}
          </div>
        </PageContent>
      </section>
    );
  };
  const GET_IMAGES = graphql`
    query {
      dr20Image: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/dr20-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      dr40Image: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/dr40-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
