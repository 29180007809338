import { css, cx } from "linaria";
import PropTypes from "prop-types";
import React from "react";

import Link from "./Link";

const linkClass = css`
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 8px;
  min-height: 48px;
  width: fit-content;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;
  transition: all 250ms ease;
  width: fit-content;
  &:hover {
    text-decoration: none;
  }
`;

const style = {
  blueBorder: cx(
    linkClass,
    css`
      border: solid 1px var(--blue-500);
      color: var(--blue-500);
      &:hover {
        background-color: rgb(60, 135, 236, 0.2);
      }
    `
  ),
  navyBorder: cx(
    linkClass,
    css`
      border: solid 1px var(--blue-900);
      color: var(--blue-900);
      &:hover {
        background-color: var(--blue-500);
        border-color: var(--blue-500);
        color: var(--white);
      }
    `
  ),
  whiteBorder: cx(
    linkClass,
    css`
      border: solid 1px white;
      color: white;
      &:hover {
        background-color: white;
        color: var(--blue-500);
      }
    `
  ),
  whiteFill: cx(
    linkClass,
    css`
      color: var(--blue-500);
      background: white;
      transition: all 500ms ease;
      position: relative;
      overflow: hidden;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: -2;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-image: linear-gradient(270deg, #e3f8fb, #cde1f8);
        background-size: 300% 100%;
        transition: all 500ms ease;
        z-index: -1;
      }
      &:hover {
        &:before {
          width: 100%;
        }
      }
    `
  ),
  blueFill: cx(
    linkClass,
    css`
      color: white;
      border: solid 1px var(--blue-500);
      background-color: var(--blue-500);
      &:hover {
        background-color: #3379d6;
      }
    `
  ),
  navy: cx(
    linkClass,
    css`
      color: white;
      background-color: var(--blue-900);
      &:hover {
        background-color: var(--blue-500);
      }
    `
  ),
  gradientFill: cx(
    linkClass,
    css`
      color: var(--nuetral-100);
      background-image: linear-gradient(
        90deg,
        var(--blue-500),
        var(--teal-500),
        var(--blue-500),
        var(--teal-500)
      );
      background-size: 300% 100%;

      &:hover {
        animation: animateBg 3s linear infinite;
        @keyframes animateBg {
          0% {
            background-position: 100% 0%;
          }
          100% {
            background-position: 0% 0%;
          }
        }
      }
    `
  ),
  hero: css`
    background-color: var(--blue-500);
    border-radius: 0.625rem;
    border: solid 2px #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 0.6px;
    outline: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
    &:active {
      box-shadow: none;
    }
  `,
};

/**
 * Link styled to look like a round button.
 * Has special handling for internal links (they open w/o a full page load).
 */

/**
 * @deprecated
 */
class RoundLink extends React.PureComponent {
  render() {
    return (
      <Link
        data-gtm="button"
        {...this.props}
        className={cx(style[this.props.appearance], this.props.className)}
      />
    );
  }
}
RoundLink.propTypes = {
  /**
   * The button either has a blue border or a blue fill.
   */
  appearance: PropTypes.oneOf([
    "blueBorder",
    "blueFill",
    "whiteBorder",
    "gradientFill",
    "hero",
    "whiteFill",
    "navy",
  ]).isRequired,
  /**
   * Optional additional class name to add to the anchor tag in addition
   * to the class that makes the link look like a button.
   */
  className: PropTypes.string,
};
RoundLink.defaultProps = {
  className: null,
};
export default RoundLink;
