import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";

import RhombusButton from "../RhombusButton";

const imageStyles = css`
  width: 100% !important;
`;

const BladeInner = styled(SectionInner)`
  flex-direction: row;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;
const BladeBlurb = styled(MainParagraph)`
  font-weight: lighter;
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const AccessControlBanner = ({ color = "var(--gradient-light)" }) => {
  return (
    <SectionContainer
      style={{
        position: "relative",
        minHeight: "500px",
        background: color,
      }}
    >
      <BladeInner>
        <SectionCol>
          <StaticImage
            src="../../access-control/img/access-control-cta.png"
            className={imageStyles}
            alt="Poduim with two Rhombus cameras and a computer and phone showing the console"
            quality={100}
            placeholder="blurred"
            objectFit="contain"
          />
        </SectionCol>
        <SectionCol style={{ zIndex: "2" }} className="col">
          <TextContainer style={{ gap: "1.6rem" }}>
            <FancyTitle>See Rhombus Access Control</FancyTitle>
            <BladeBlurb>
              Request a demo with our team today and see how you can transform
              building management in your spaces.
            </BladeBlurb>
            <RhombusButton
              path="/lp/access-control-a/"
              type="primary"
              title="Request Demo"
            />
          </TextContainer>
        </SectionCol>
      </BladeInner>
    </SectionContainer>
  );
};

export default AccessControlBanner;
